import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

export default () => (
    <Layout>
      <SEO
        title={'Seiko Watches'}
        description="Seiko at Underood's Jewelers" />
        <br /><br />
      <div css={`text-align:center;`}>
        <div>Founded in 1881 in Tokyo, <strong>Seiko</strong> is a world leader in the watch industry dedicated to perfection.</div>
        <br />
        <div><em><span><strong></strong></span><strong>Underwood Jewelers is an Authorized Dealer of Seiko watches.</strong></em></div>
        <br /><br />
        <iframe css={`width:100%;min-height:100vh;border:0;`} title="seiko iframe" allowFullScreen src="https://loremipsumcorp.com/seikoretailer/" ></iframe>
      </div>
    </Layout>
)
